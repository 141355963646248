import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Home from './components/Library/HomePage';
import Club from './components/Club/ClubPage';
import Chat from './components/Chat/ChatPage';
import Request from './components/Request/RequestPage';
import Archive from './components/Archive/ArchivePage';
import History from './components/History/HistoryPage';
import Book from './components/Library/BookPage';
import AboutClub from './components/Club/AboutClub';
import './index.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<SignIn />}></Route>
          <Route path='/signup' element={<SignUp />}></Route>
          <Route path='/homepage' element={<Home />}></Route>
          <Route path='/clubpage' element={<Club />}></Route>
          <Route path='/chatpage' element={<Chat />}></Route>
          <Route path='/requestpage' element={<Request />}></Route>
          <Route path='/historypage' element={<History />}></Route>
          <Route path='/archivepage' element={<Archive />}></Route>
          <Route path="/homepage/view/:id" element={<Book />} />
          <Route path="/clubpage/view/:id" element={<AboutClub />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
