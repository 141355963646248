import React, { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';

function VideoUpload({ handleVideoSubmit }) {
  const [videoTitle, setVideoTitle] = useState('');
  const [videoProducer, setVideoProducer] = useState('');
  const [episode, setEpisode] = useState('');
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleVideoSubmit({ 
        title: videoTitle, 
        producer: videoProducer,
        episode: episode,
        video: video,
        image: image
     });
    setVideoTitle('');
    setVideoProducer('');
    setEpisode('');
    setVideo(null);
    setImage(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile.type === 'video/mp4') {
      setVideo(droppedFile);
    } else {
      alert('Please drop a MP4 file.');
    }
  };
  
  const handleCoverClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/jpeg, image/png";
    input.onchange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };
    input.click();
  };

  const handleCoverDrop = (event) => {
    event.preventDefault();
    const droppedCover = event.dataTransfer.files[0];
    if (droppedCover.type === 'image/jpeg' || droppedCover.type === 'image/png') {
      setImage(droppedCover);
    } else {
      alert('Please drop a JPEG or PNG file.');
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
  };

  const handleRemoveVideo = () => {
    setVideo(null);
  };

  const handleVideoClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "video/mp4";
    input.onchange = (e) => {
        const file = e.target.files[0];
        setVideo(file);
    };
    input.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCoverDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label className='book-title'>
          Video Title
          <input className='book-input' placeholder='Concept of Design' type="text" value={videoTitle} onChange={(event) => setVideoTitle(event.target.value)} />
        </label>

        <label className='producer'>
          Producer
          <input className='producer-input' placeholder='Sophie Kalu' type="text" value={videoProducer} onChange={(event) => setVideoProducer(event.target.value)} />
        </label>

        <label className='episode'>
          Episode
          <input type="text" className='episode-input' placeholder='2' value={episode} onChange={(event) => setEpisode(event.target.value)} />
        </label>

        <label className='vid-title'>Attach Publication Cover</label>
        <div 
            className='videoCover-upload' 
            onDrop={handleCoverDrop} 
            onDragOver={handleCoverDragOver} 
            onClick={handleCoverClick}
            >
            {!image ? (
                <div>Drag and Drop or Click to Browse</div>
            ) : (
                <div>
                {image.name}
                <span onClick={handleRemoveImage}>
                    <MdCancel />
                </span>
                </div>
            )}
        </div>
        <label className='jpg-upload'>Attached file type: JPEG/PNG</label>
        
        <label className='videohome-title'>Attach Publication File</label>
        <div 
            className='video-upload' 
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleVideoClick}
            >
            {!video ? (
                <div>Drag and Drop or Click to Browse</div>
            ) : (
                <div>
                {video.name}
                <span onClick={handleRemoveVideo}>
                    <MdCancel />
                </span>
                </div>
            )}
        </div>
        <label className='vidpdf-upload'>Attached file type: MP4</label>

        <button type="submit" className='vid-upload'>
            <span><AiOutlineCloudUpload /></span>
            Upload
        </button>
      </form>
    </div>
  );
}

export default VideoUpload;