import React from "react";
import BookNavbar from "../ui/BookNavbar";
import Clubs from './ClubPage';

const Comments = [
    {
        id: 1,
        commentAuthor: "Dami Segun",
        comment: "Nice book!",
        date: "19/03/21"
    },
    {
        id: 2,
        commentAuthor: "Dami Segun",
        comment: "Nice book!",
        date: "19/03/21"
    },
    {
        id: 3,
        commentAuthor: "Dami Segun",
        comment: "Nice book!",
        date: "19/03/21"
    }
]

export default function AboutClub(props){
    return(
        <div>
            <BookNavbar />
        </div>
    );
}