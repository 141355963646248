import React , { useState, useEffect, useRef } from "react";
import Navbar from "../ui/Navbar";
import bookImg from "../../images/Rectangle 163.png";
import Rating from "../ui/Rating";
import { BiArrowBack } from "react-icons/bi";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import author from "../../images/avatar.png";
import { 
  AiOutlineArrowRight, 
  AiOutlineRead, 
  AiOutlineEye,
  AiOutlineCloudUpload
} 
from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import Upload from "./Upload";

const HomeData = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234",
    rating: "4"
  },
  {
    id: 2,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234",
    rating: "4"
  },
  {
    id: 3,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234",
    rating: "4"
  },
  {
    id: 4,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234",
    rating: "4"
  },
  {
    id: 5,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234",
    rating: "4"
  },
  {
    id: 6,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234",
    rating: "4"
  },
];

const booksStatus = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    returnDate: "14th July 2021",
    bookStatus: "Approved",
    currentDate: "02 July 2021",
    time: "12:45pm"
  },
  {
    id: 2,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    returnDate: "14th July 2021",
    bookStatus: "Rejected",
    currentDate: "11 July 2021",
    time: "12:45pm"
  },
  {
    id: 3,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    returnDate: "14th July 2021",
    bookStatus: "Pending",
    currentDate: "02 July 2021",
    time: "12:45pm"
  }
];

const readingList = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    rating: "4.0",
    currentDate: "02 July 2021",
    time: "12:45pm"
  }
];

const mostRead = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    rating: "4.0",
    views: "1000"
  }
]

const mostActive = [
  {
    id: 1,
    name: "Dami Segun",
    userImage: author,
    views: "1000",
    class: "SS3"
  },
  {
    id: 2,
    name: "Dami Segun",
    userImage: author,
    views: "1000",
    class: "SS3"
  }
]

const verifyStudent = [
  {
    id: 1,
    userImage: author,
    name: "Dami Segun",
    class: "SS3",
    date: "02 July 2021",
    time: "12:45pm"
  },
  {
    id: 2,
    userImage: author,
    name: "Dami Segun",
    class: "SS3",
    date: "02 July 2021",
    time: "12:45pm"
  }
]

export default function Home() {
  const [hoveredBookId, setHoveredBookId] = useState(null);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");

  const searchBooks = (term) => {
    setSearchTerm(term);
  };

  const filteredBooks = HomeData.filter((book) => {
    return (
      book.bookName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      book.bookAuthor.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const [isHidden, setIsHidden] = useState(true);
  const [isListHidden, setListHidden] = useState(true);
  const [isViewsHidden, setViewsHidden] = useState(true);
  const [isUsersHidden, setUsersHidden] = useState(true);
  const [isVerifyHidden, setVerifyHidden] = useState(true);
  const [isUploadHidden, setUploadHidden] = useState(true);
  const statusRef = useRef(null);
  const listRef = useRef(null);
  const viewRef = useRef(null);
  const userRef = useRef(null);
  const verifyRef = useRef(null);
  const uploadRef = useRef(null);

  useEffect(() => {
    const statusContainer = statusRef.current;
    statusContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const listContainer = listRef.current;
    listContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const viewContainer = viewRef.current;
    viewContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const userContainer = userRef.current;
    userContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const verifyContainer = verifyRef.current;
    verifyContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const uploadContainer = uploadRef.current;
    uploadContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  const togglePanel = () => {
    setIsHidden(!isHidden);
  };

  const listTogglePanel = () => {
    setListHidden(!isListHidden);
  };

  const viewTogglePanel = () => {
    setViewsHidden(!isViewsHidden);
  };

  const userTogglePanel = () => {
    setUsersHidden(!isUsersHidden);
  };

  const verifyTogglePanel = () => {
    setVerifyHidden(!isVerifyHidden);
  };

  const uploadTogglePanel = () => {
    setUploadHidden(!isUploadHidden);
  };

  const containerStyle = {
    transform: `translateX(${isHidden ? 100 : 0}%)`
  };

  const listStyle = {
    transform: `translateX(${isListHidden ? 100 : 0}%)`
  };

  const viewStyle = {
    transform: `translateX(${isViewsHidden ? 100 : 0}%)`
  };

  const userStyle = {
    transform: `translateX(${isUsersHidden ? 100 : 0}%)`
  };

  const verifyStyle = {
    transform: `translateX(${isVerifyHidden ? 100 : 0}%)`
  };

  const uploadStyle = {
    transform: `translateX(${isUploadHidden ? 100 : 0}%)`
  };

  const handleMaskClick = () => {
    setIsHidden(true);
  };

  const handleListClick = () => {
    setListHidden(true);
  };

  const handleViewsClick = () => {
    setViewsHidden(true);
  };

  const handleUsersClick = () => {
    setUsersHidden(true);
  };

  const handleVerifyClick = () => {
    setVerifyHidden(true);
  };

  const handleUploadClick = () => {
    setUploadHidden(true);
  };

  return (
    <div>
      <Navbar onSearch={searchBooks} setSearchTerm={setSearchTerm} />
      <span className="header">Library</span>
      <div className="image-grid-container">
        <div className="image-grid">
          {filteredBooks.map((item) => {
            return (
              <div
                key={item.id}
                className="image-container"
                onMouseEnter={() => setHoveredBookId(item.id)}
                onMouseLeave={() => setHoveredBookId(null)}
              >
                <img src={item.bookImage} alt="Math Book" />
                {hoveredBookId === item.id && (
                  <div className="popup">
                    <h3>{item.bookName}</h3>
                    <p className="author">{item.bookAuthor}</p>
                    <p className="reviews">{item.reviews} Reviews</p>
                    <Rating rating={item.rating} />
                    <button onClick={() => navigate(`/homepage/view/${item.id}`)} className="view"><span><AiOutlineEye /> View</span></button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="status-mask" style={{ display: isUploadHidden ? "none" : "block" }} onClick={handleUploadClick}></div>
      <div className="status-wrapper" style={uploadStyle} ref={uploadRef}>
        <div className="statusContainer">
          <h3>Upload Personal Material</h3>
          <button onClick={uploadTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="upload-container">
            <Upload />
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isHidden ? "none" : "block" }} onClick={handleMaskClick}></div>
      <div className="status-wrapper" style={containerStyle} ref={statusRef}>
        <div className="statusContainer">
          <h3>Borrowed Book Status</h3>
          <button onClick={togglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {booksStatus.map((book) => (
              <div key={book.id} className="book-item">
                <img src={book.bookImage} alt={book.bookName} className='book-image'></img>
                <p className="book-name">{book.bookName}</p>
                <p className="book-author">{book.author}</p>
                <p className="return-date">Return Date</p>
                <p className='return'>{book.returnDate}</p>
                <p className='state' style={{ color: book.bookStatus === "Approved" ? "green" : book.bookStatus === "Rejected" ? "red" : "orange" }}>{book.bookStatus}</p>
                <p className="current-time">{book.time}</p>
                <p className="current-date">{book.currentDate}</p>
                {book.bookStatus === "Approved" && (
                  <button className="book-read"><span><AiOutlineRead /></span><p>Read</p></button>
                )}    
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isListHidden ? "none" : "block" }} onClick={handleListClick}></div>
      <div className="status-wrapper" style={listStyle} ref={listRef}>
        <div className="statusContainer">
          <h3>My Reading List</h3>
          <button onClick={listTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {readingList.map((book) => (
              <div key={book.id} className="book-item">
                <img src={book.bookImage} alt={book.bookName} className='book-image'></img>
                <p className="book-name">{book.bookName}</p>
                <p className="book-author">{book.author}</p>
                <span className="book-rating"><Rating rating={book.rating}/></span>
                <p className="list-time">{book.time}</p>
                <p className="list-date">{book.currentDate}</p>
                <button className="book-view"><span><AiOutlineEye /></span><p>View</p></button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isViewsHidden ? "none" : "block" }} onClick={handleViewsClick}></div>
      <div className="status-wrapper" style={viewStyle} ref={viewRef}>
        <div className="statusContainer">
          <h3>Most Read Books</h3>
          <button onClick={viewTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {mostRead.map((book) => (
              <div key={book.id} className="book-item">
                <img src={book.bookImage} alt={book.bookName} className='book-image'></img>
                <p className="book-name">{book.bookName}</p>
                <p className="book-author">{book.author}</p>
                <span className="book-rating"><Rating rating={book.rating}/></span>
                <p className="views"><span><AiOutlineEye /></span>{book.views}</p>
                <button className="book-view"><span><AiOutlineEye /></span><p>View</p></button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isUsersHidden ? "none" : "block" }} onClick={handleUsersClick}></div>
      <div className="status-wrapper" style={userStyle} ref={userRef}>
        <div className="statusContainer">
          <h3>Most Active Users</h3>
          <button onClick={userTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="user">
            {mostActive.map((user) => (
              <div key={user.id} className="user-item">
                <img src={user.userImage} alt={user.name} className='user-image'></img>
                <p className="user-name">{user.name}</p>
                <p className="user-class">{user.class}</p>
                <p className="user-views"><span><AiOutlineEye /></span>{user.views}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isVerifyHidden ? "none" : "block" }} onClick={handleVerifyClick}></div>
      <div className="status-wrapper" style={verifyStyle} ref={verifyRef}>
        <div className="statusContainer">
          <h3>Verify Student</h3>
          <button onClick={verifyTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {verifyStudent.map((user) => (
              <div key={user.id} className="useritem">
                <img src={user.userImage} alt={user.name} className='userimage'></img>
                <p className="uname">{user.name}</p>
                <p className="uclass">{user.class}</p>
                <p className="udate">{user.date}</p>
                <p className="utime">{user.time}</p>
                <button className="reject"><span><MdCancel /></span><p>Reject</p></button>
                <button className="approve"><span><TiTick /></span><p>Approve</p></button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="homeButtonContainer">
        <div className="borrow-book">
          <span><AiOutlineCloudUpload /></span>
          <button onClick={uploadTogglePanel}>Upload Personal Materials</button>
        </div>

        <div className="status-home">
          <span className="home-arrow"><AiOutlineArrowRight /></span>
          <button onClick={togglePanel} className="status-button">
            Borrow Book Status
            {booksStatus.length > 0 && (
              <span className="home-status-indicator">{booksStatus.length}</span>
            )}
          </button>
        </div>

        <div className="list-home">
          <span><AiOutlineArrowRight /></span>
          <button onClick={listTogglePanel}>My Reading List</button>
        </div>

        <div className="read-home">
          <span><AiOutlineArrowRight /></span>
          <button onClick={viewTogglePanel}>Most Read Books</button>
        </div>

        <div className="activeUsers-home">
          <span><AiOutlineArrowRight /></span>
          <button onClick={userTogglePanel}>Most Active Users</button>
        </div>

        <div className="verify-home">
          <span><AiOutlineArrowRight /></span>
          <button onClick={verifyTogglePanel}>Verify Student</button>
          {verifyStudent.length > 0 && (
            <span className="home-verify-indicator">{verifyStudent.length}</span>
          )}
        </div>
      </div>
    </div>
  );
}