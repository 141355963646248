import React, { useState, useEffect, useRef } from "react";
import BookNavbar from "../ui/BookNavbar";
import bookImg from "../../images/Rectangle 163.png";
import Rating from "../ui/Rating";
import { BiArrowBack } from "react-icons/bi";
import { FiSend } from 'react-icons/fi';
import { TiArrowBack, TiArrowForward, TiTick } from "react-icons/ti";
import { TbMinusVertical } from 'react-icons/tb';
import { useNavigate, useParams } from "react-router-dom";
import author from "../../images/avatar.png";
import { FaExchangeAlt } from 'react-icons/fa';
import { 
  AiOutlineArrowRight, 
  AiOutlinePlus , 
  AiOutlineRead, 
  AiOutlineEye } 
from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';

const Data = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234 reviews",
    rating: "4.0",
  },
  {
    id: 2,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234 reviews",
    rating: "4.0",
  },
  {
    id: 3,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234 reviews",
    rating: "4.0",
  },
  {
    id: 4,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234 reviews",
    rating: "4.0",
  },
  {
    id: 5,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234 reviews",
    rating: "4.0",
  },
  {
    id: 6,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course",
    bookAuthor: "By Abraham Williams and Jane Fredrick",
    reviews: "234 reviews",
    rating: "4.0",
  },
];

const Reviews = [
  {
    id: 1,
    bookName: "Holt Middle School Math Course",
    author: "Segun Favour",
    date: "08/01/2020",
    authorImage: author,
    rating: "4.0",
    details: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet"
  },
  {
    id: 2,
    bookName: "Holt Middle School Math Course",
    author: "Anna Kadzai",
    date: "08/01/2020",
    authorImage: author,
    rating: "4.0",
    details: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet"
  },
  {
    id: 3,
    bookName: "Holt Middle School Math Course",
    author: "Anna Kadzai",
    date: "08/01/2020",
    authorImage: author,
    rating: "4.0",
    details: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet"
  }
];

const booksStatus = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    returnDate: "14th July 2021",
    bookStatus: "Approved",
    currentDate: "02 July 2021",
    time: "12:45pm"
  },
  {
    id: 2,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    returnDate: "14th July 2021",
    bookStatus: "Rejected",
    currentDate: "11 July 2021",
    time: "12:45pm"
  },
  {
    id: 3,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    returnDate: "14th July 2021",
    bookStatus: "Pending",
    currentDate: "02 July 2021",
    time: "12:45pm"
  }
];

const readingList = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    rating: "4.0",
    currentDate: "02 July 2021",
    time: "12:45pm"
  }
];

const mostRead = [
  {
    id: 1,
    bookImage: bookImg,
    bookName: "Holt Middle School Math Course 3",
    author: "Abraham Williams",
    rating: "4.0",
    views: "1000"
  }
]

const mostActive = [
  {
    id: 1,
    name: "Dami Segun",
    userImage: author,
    views: "1000",
    class: "SS3"
  },
  {
    id: 2,
    name: "Dami Segun",
    userImage: author,
    views: "1000",
    class: "SS3"
  }
]

const verifyStudent = [
  {
    id: 1,
    userImage: author,
    name: "Dami Segun",
    class: "SS3",
    date: "02 July 2021",
    time: "12:45pm"
  },
  {
    id: 2,
    userImage: author,
    name: "Dami Segun",
    class: "SS3",
    date: "02 July 2021",
    time: "12:45pm"
  }
]

export default function Book(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const bookDetails = Data.filter((book) => book.id === parseInt(id))[0];
  const reviews = Reviews.filter((review) => review.bookName === bookDetails.bookName);
  
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleNavigation = (bookId) => {
    const numId = parseInt(bookId);
    if (numId >= 1 && numId <= 6) {
      navigate(`/homepage/view/${bookId}`);
    }
  };

  const reviewsToDisplay = showAllReviews ? reviews : reviews.slice(0, 2);

  const toggleShowAllReviews = () => {
    setShowAllReviews(!showAllReviews);
  };

  const [isHidden, setIsHidden] = useState(true);
  const [isListHidden, setListHidden] = useState(true);
  const [isViewsHidden, setViewsHidden] = useState(true);
  const [isUsersHidden, setUsersHidden] = useState(true);
  const [isVerifyHidden, setVerifyHidden] = useState(true);
  const statusRef = useRef(null);
  const listRef = useRef(null);
  const viewRef = useRef(null);
  const userRef = useRef(null);
  const verifyRef = useRef(null);

  useEffect(() => {
    const statusContainer = statusRef.current;
    statusContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const listContainer = listRef.current;
    listContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const viewContainer = viewRef.current;
    viewContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const userContainer = userRef.current;
    userContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  useEffect(() => {
    const verifyContainer = verifyRef.current;
    verifyContainer.style.transition = "transform 0.3s ease-out";
  }, []);

  const togglePanel = () => {
    setIsHidden(!isHidden);
  };

  const listTogglePanel = () => {
    setListHidden(!isListHidden);
  };

  const viewTogglePanel = () => {
    setViewsHidden(!isViewsHidden);
  };

  const userTogglePanel = () => {
    setUsersHidden(!isUsersHidden);
  };

  const verifyTogglePanel = () => {
    setVerifyHidden(!isVerifyHidden);
  };

  const containerStyle = {
    transform: `translateX(${isHidden ? 100 : 0}%)`
  };

  const listStyle = {
    transform: `translateX(${isListHidden ? 100 : 0}%)`
  };

  const viewStyle = {
    transform: `translateX(${isViewsHidden ? 100 : 0}%)`
  };

  const userStyle = {
    transform: `translateX(${isUsersHidden ? 100 : 0}%)`
  };

  const verifyStyle = {
    transform: `translateX(${isVerifyHidden ? 100 : 0}%)`
  };

  const handleMaskClick = () => {
    setIsHidden(true);
  };

  const handleListClick = () => {
    setListHidden(true);
  };

  const handleViewsClick = () => {
    setViewsHidden(true);
  };

  const handleUsersClick = () => {
    setUsersHidden(true);
  };

  const handleVerifyClick = () => {
    setVerifyHidden(true);
  };

  const [rating, setRating] = useState(null);

  const handleRatingClick = (value) => {
    setRating(value);
  };

  const [text, setText] = useState('');

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= 200) {
      setText(newText);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(`Submitted text: ${text}`);
  };

  return (
    <div>
      <BookNavbar />
      <a href="/homepage" className="back" onClick={() => navigate("/homepage")}>
        <span>
          <BiArrowBack /> Back
        </span>
      </a>
      <button
        className="previous"
        onClick={() => handleNavigation(parseInt(id) - 1)}
        disabled={id <= 1}
      >
        <span>
          <TiArrowBack /> Previous Book
        </span>
      </button>
      <div className="vertical">
        <TbMinusVertical />
      </div>
      <button
        className="next"
        onClick={() => handleNavigation(parseInt(id) + 1)}
        disabled={id >= 6}
      >
        <span>
          Next Book <TiArrowForward />
        </span>
      </button>

      <div className="bookcontainer">
        <div className="right-column">
          <h3>{bookDetails.bookName}</h3>
          <p className="author">{bookDetails.bookAuthor}</p>
          <img src={bookDetails.bookImage} alt="Math Book" />
        </div>
        <div className="left-column">
          <p className="ratingsHeader">Ratings and Reviews</p>
          <span><Rating rating={bookDetails.rating} /></span>
          <p className="rating">{bookDetails.rating}</p>
          <p className="review">{bookDetails.reviews}</p>
          {reviewsToDisplay.map((review) => (
          <div key={review.id} className="review-item">
            <img src={review.authorImage} alt='Avatar'/>
            <div className="review-details">
              <p className="reviewAuthor">{review.author}</p>
              <p className="reviewDate">{review.date}</p>
              <span><Rating rating={review.rating} /></span>
              <p className="reviewDetails">{review.details}</p>
            </div>
          </div>
          ))}
          {reviews.length > 2 && !showAllReviews && (
            <button className='show' onClick={toggleShowAllReviews}>View More</button>
          )}
          {reviews.length > 2 && showAllReviews && (
            <button className='show' onClick={toggleShowAllReviews}>View Less</button>
          )}
        </div>
      </div>

      <div className="status-mask" style={{ display: isHidden ? "none" : "block" }} onClick={handleMaskClick}></div>
      <div className="status-wrapper" style={containerStyle} ref={statusRef}>
        <div className="statusContainer">
          <h3>Borrowed Book Status</h3>
          <button onClick={togglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {booksStatus.map((book) => (
              <div key={book.id} className="book-item">
                <img src={book.bookImage} alt={book.bookName} className='book-image'></img>
                <p className="book-name">{book.bookName}</p>
                <p className="book-author">{book.author}</p>
                <p className="return-date">Return Date</p>
                <p className='return'>{book.returnDate}</p>
                <p className='state' style={{ color: book.bookStatus === "Approved" ? "green" : book.bookStatus === "Rejected" ? "red" : "orange" }}>{book.bookStatus}</p>
                <p className="current-time">{book.time}</p>
                <p className="current-date">{book.currentDate}</p>
                {book.bookStatus === "Approved" && (
                  <button className="book-read"><span><AiOutlineRead /></span><p>Read</p></button>
                )}    
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isListHidden ? "none" : "block" }} onClick={handleListClick}></div>
      <div className="status-wrapper" style={listStyle} ref={listRef}>
        <div className="statusContainer">
          <h3>My Reading List</h3>
          <button onClick={listTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {readingList.map((book) => (
              <div key={book.id} className="book-item">
                <img src={book.bookImage} alt={book.bookName} className='book-image'></img>
                <p className="book-name">{book.bookName}</p>
                <p className="book-author">{book.author}</p>
                <span className="book-rating"><Rating rating={book.rating}/></span>
                <p className="list-time">{book.time}</p>
                <p className="list-date">{book.currentDate}</p>
                <button className="book-view"><span><AiOutlineEye /></span><p>View</p></button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isViewsHidden ? "none" : "block" }} onClick={handleViewsClick}></div>
      <div className="status-wrapper" style={viewStyle} ref={viewRef}>
        <div className="statusContainer">
          <h3>Most Read Books</h3>
          <button onClick={viewTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {mostRead.map((book) => (
              <div key={book.id} className="book-item">
                <img src={book.bookImage} alt={book.bookName} className='book-image'></img>
                <p className="book-name">{book.bookName}</p>
                <p className="book-author">{book.author}</p>
                <span className="book-rating"><Rating rating={book.rating}/></span>
                <p className="views"><span><AiOutlineEye /></span>{book.views}</p>
                <button className="book-view"><span><AiOutlineEye /></span><p>View</p></button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isUsersHidden ? "none" : "block" }} onClick={handleUsersClick}></div>
      <div className="status-wrapper" style={userStyle} ref={userRef}>
        <div className="statusContainer">
          <h3>Most Active Users</h3>
          <button onClick={userTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="user">
            {mostActive.map((user) => (
              <div key={user.id} className="user-item">
                <img src={user.userImage} alt={user.name} className='user-image'></img>
                <p className="user-name">{user.name}</p>
                <p className="user-class">{user.class}</p>
                <p className="user-views"><span><AiOutlineEye /></span>{user.views}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="status-mask" style={{ display: isVerifyHidden ? "none" : "block" }} onClick={handleVerifyClick}></div>
      <div className="status-wrapper" style={verifyStyle} ref={verifyRef}>
        <div className="statusContainer">
          <h3>Verify Student</h3>
          <button onClick={verifyTogglePanel}>
            <span>
              <BiArrowBack /> Back
            </span>
          </button>
          <div className="books">
            {verifyStudent.map((user) => (
              <div key={user.id} className="useritem">
                <img src={user.userImage} alt={user.name} className='userimage'></img>
                <p className="uname">{user.name}</p>
                <p className="uclass">{user.class}</p>
                <p className="udate">{user.date}</p>
                <p className="utime">{user.time}</p>
                <button className="reject"><span><MdCancel /></span><p>Reject</p></button>
                <button className="approve"><span><TiTick /></span><p>Approve</p></button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="buttonContainer">
        <div className="borrow">
          <span><FaExchangeAlt /></span>
          <button>Borrow Book</button>
        </div>

        <div className="addList">
          <span><AiOutlinePlus /></span>
          <button>Add Book to Reading List</button>
        </div>

        <div className="status">
          <span className="arrow"><AiOutlineArrowRight /></span>
          <button onClick={togglePanel} className="status-button">
            Borrow Book Status
            {booksStatus.length > 0 && (
              <span className="status-indicator">{booksStatus.length}</span>
            )}
          </button>
        </div>

        <div className="list">
          <span><AiOutlineArrowRight /></span>
          <button onClick={listTogglePanel}>My Reading List</button>
        </div>

        <div className="read">
          <span><AiOutlineArrowRight /></span>
          <button onClick={viewTogglePanel}>Most Read Books</button>
        </div>

        <div className="activeUsers">
          <span><AiOutlineArrowRight /></span>
          <button onClick={userTogglePanel}>Most Active Users</button>
        </div>

        <div className="verify">
          <span><AiOutlineArrowRight /></span>
          <button onClick={verifyTogglePanel}>Verify Student</button>
          {verifyStudent.length > 0 && (
            <span className="verify-indicator">{verifyStudent.length}</span>
          )}
        </div>

        <p className="review-title">Write your Review</p>
        <img src={author} alt='avatar' className="review-avatar"></img>
        <p className="review-author">Catherine Thomas</p>
        <p className="rating-title">Select Rating</p>
        <div className="rating-stars">
          {[1, 2, 3, 4, 5].map((value) => (
            <span
              key={value}
              onClick={() => handleRatingClick(value)}
              style={{ color: rating && value <= rating ? 'gold' : 'gray', cursor: 'pointer' }}
            >
              ★
            </span>
          ))}
        </div>
        <p className="date">08/11/2021</p>
        <p className="review-prompt">Write Review</p>
        <textarea
          className="review-box"
          placeholder="Please write a review here in less than 200 characters"
          value={text}
          onChange={handleTextChange}
          maxLength={200}
        >
        </textarea>
        <button onClick={handleSubmit} className='send-review'><FiSend />Submit</button>
      </div>
    </div>
  );
}